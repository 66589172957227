import axios from 'axios';
import {createRefresh} from 'react-auth-kit'
import {BASE_API_URL} from "./components/consts/api"


const refreshApi = createRefresh({
  interval: 10,
  refreshApiCallback: async (param) => {
    var data = param
    data["refresh"] = param["refreshToken"]
    try {
      const response = await axios.post(`${BASE_API_URL}token/refresh/`, data, {
        headers: {'Authorization': `Bearer ${param.authToken}`}}
      )
      return {
        isSuccess: true,
        newAuthToken: response.data.access,
        newAuthTokenExpireIn: 300,
        newRefreshTokenExpiresIn: 1440
      }
    }
    catch(error){
      console.error(error)
      return {
        isSuccess: false
      } 
    }    
  }
})

export default refreshApi