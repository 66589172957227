import { React, useState, useEffect } from "react";
import Gallery from "./Gallery";
import BackgroundImage from "../assets/background/gallery-background.png";
import { Oval } from "react-loader-spinner";
import { BASE_API_URL } from "../components/consts/api"
import { useAuthHeader } from "react-auth-kit";

const AboutGallerySection = () => {
  const auth = useAuthHeader();

  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [galleryList, setGalleryList] = useState([]);
  useEffect(() => {
    const getPictures = async () => {
      setLoading(true);
      await fetch(`${BASE_API_URL}pictures/`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Authorization": auth(),
        },
      })
        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then(function (data) {
          if (Array.isArray(data)) {
            setGalleryList(data);
          } else {
            setLoading(false);
            setErrorMsg(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorMsg("Błąd serwera 👨‍💻");
        });
    };
    getPictures();
  }, []);

  return (
    <div className="gallery-section-wrapper">
      <p className="error">{errorMsg}</p>
      {loading ? (
        <div className="loading-container">
          <Oval
            height={60}
            width={60}
            color="#fe9e0d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#fe9e0d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <div className="gallery-background">
            <img src={BackgroundImage} alt="" />
          </div>
          <div className="gallery-section-top">
            <p class="primary-subheading">Galeria</p>
            <h1 className="primary-heading">Nasze najfajniejsze zdjęcia</h1>
          </div>
          <div className="gallery-section-bottom">
            <Gallery images={galleryList} />
          </div>
        </>
      )}
    </div>
  );
};

export default AboutGallerySection;
