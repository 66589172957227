import React from "react";
import BannerBackground from "../assets/background/youtube-section-background.png";
import { BsFillPlayBtnFill } from "react-icons/bs";
import ReactPlayer from "react-player";

const YoutubeSection = () => {
  return (
    <div className="youtube-section-container">
      <div className="youtube-background-image-container">
        <img src={BannerBackground} alt="Kanał YT" />
      </div>
      <div className="youtube-section-image-container">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=IUIaMlreiYQ"
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
      <div className="youtube-section-text-container">
        <p className="primary-subheading">YouTube</p>
        <h1 className="primary-heading">Wspomnienia zapisane w filmach</h1>
        <p className="primary-text">Na kanale YouTube "Grupy jak 5zł" znajdują się filmy upamiętniające nasze wspólne chwile</p>
        <div className="youtube-buttons-container">
          <a
            className="a-button"
            href="https://www.youtube.com/@fabiantarasiuk5906"
          >
            <button className="secondary-button">Zobacz nasz kanał</button>
          </a>
          <a
            className="a-button"
            href="https://www.youtube.com/watch?v=IUIaMlreiYQ"
          >
            <button className="watch-video-button">
              <BsFillPlayBtnFill />
              Zwiastun na YouTube
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default YoutubeSection;
