import React from "react";
import Image from "../assets/aboutPage/gallery/grupa26.png";
import { Link } from "react-router-dom";
import BackgroundImage from "../assets/background/about-history-background.png";

const AbooutHistorySection = () => {
  return (
    <div className="about-history-section-wrapper">
      <div className="about-history-background">
        <img src={BackgroundImage} alt="" />
      </div>
      <div className="about-history-section">
        <div className="about-history-section-image">
          <img src={Image} alt="" />
        </div>
        <div className="about-history-section-description">
          <p class="primary-subheading">Historia</p>
          <h1 className="primary-heading">Historia grupy</h1>
          <p className="primary-text">
            Za oficjalną datę założenie "Grupy jak 5 zł" uważa się dzień
            24.10.2019 r. Tego dnia król Tevorg V podczas najazdu na Ignatki
            zyskał siłę goryla po zażyciu tajemniczej mikstury zwanej
            "Trypuciobimbrem". Od tego czasu udało mu się zwerbować 18 członków.
            Przez następne lata nastąpiło wiele zmian w ludziach, lecz aktualny{" "}
            <Link className="inside-text-link" to="people/">
              skład grupy
            </Link>{" "}
            liczy 15 osób.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AbooutHistorySection;
