import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import ScrollToTop from "./hooks/ScrollToTop";
import Navbar from "./components/Navbar";
import People from "./pages/people";
import Home from "./pages";
import Footer from "./components/Footer";
import About from "./pages/about";
import Films from "./pages/films";
import Music from "./pages/music";
import Login from "./pages/login";
import Game from "./pages/game";


const RoutesComponent = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <div className="App">
                <Routes>
                    <Route
                        path={'/'}
                        element={
                            <RequireAuth loginPath={'/login'}>
                                <header>
                                    <Navbar />
                                </header>
                                <Home />
                            </RequireAuth>
                        }
                    ></Route>
                    <Route
                        path={'/people'}
                        element={
                            <RequireAuth loginPath={'/login'}>
                                <header>
                                    <Navbar />
                                </header>
                                <People />
                            </RequireAuth>
                        }
                    ></Route>
                    <Route
                        path={'/about'}
                        element={
                            <RequireAuth loginPath={'/login'}>
                                <header>
                                    <Navbar />
                                </header>
                                <About />
                            </RequireAuth>
                        }
                    ></Route>
                    <Route
                        path={'/films'}
                        element={
                            <RequireAuth loginPath={'/login'}>
                                <header>
                                    <Navbar />
                                </header>
                                <Films />
                            </RequireAuth>
                        }
                    ></Route>
                    <Route
                        path={'/music'}
                        element={
                            <RequireAuth loginPath={'/login'}>
                                <header>
                                    <Navbar />
                                </header>
                                <Music />
                            </RequireAuth>
                        }
                    ></Route>
                    <Route path={'/game'} element={
                        <RequireAuth loginPath={'/login'}>
                            <Game />
                        </RequireAuth>
                    }></Route>
                    <Route path={'/login'} element={<Login />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
};

export default RoutesComponent;