import React from 'react'
import AboutMainSection from '../components/AboutMainSection';
import AbooutHistorySection from '../components/AbooutHistorySection';
import AboutGallerySection from '../components/AboutGallerySection';

const About = () => {
    return (
      <>
        <AboutMainSection />
        <AbooutHistorySection />
        <AboutGallerySection />
      </>
    );
  };
  
export default About;
