import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import PeopleIcon from "@mui/icons-material/People";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Admin from "@mui/icons-material/AdminPanelSettings";
import LogoImage from "../assets/logo.png";
import { BASE_BACKEND_URL } from "../components/consts/api"
import { useAuthUser } from "react-auth-kit";


const Navbar = () => {
  const auth = useAuthUser();
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Strona główna",
      url: "/",
      icon: <HomeIcon />,
    },
    {
      text: "O grupie",
      url: "/about",
      icon: <InfoIcon />,
    },

    {
      text: "Członkowie",
      url: "/people",
      icon: <PeopleIcon />,
    },
    {
      text: "Muzyka",
      url: "/music",
      icon: <MusicNoteIcon />,
    },
    {
      text: "Filmy",
      url: "/films",
      icon: <LocalMoviesIcon />,
    },
  ];
  const [activeLink, setActiveLink] = useState();
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    let path = location.pathname;
    if (path.includes("about")) {
      setActiveLink("about");
    }
    if (path.includes("people")) {
      setActiveLink("people");
    }
    if (path.includes("music")) {
      setActiveLink("music");
    }
    if (path.includes("films")) {
      setActiveLink("films");
    }
  }, [location.pathname]);

  return (
    <nav className={scrolled ? "scrolled" : ""}>
      <Link
        className={activeLink === "home" ? "active" : ""}
        onClick={() => setActiveLink("home")}
        to="/"
      >
        <div className="nav-logo-container">
          <img src={LogoImage} alt="" />
          <h3>Grupa jak 5 zł</h3>
        </div>
      </Link>
      <div className="navbar-links-container">
        <Link
          className={activeLink === "about" ? "active" : ""}
          onClick={() => setActiveLink("about")}
          to="/about"
        >
          O grupie
        </Link>
        <Link
          className={activeLink === "people" ? "active" : ""}
          onClick={() => setActiveLink("people")}
          to="/people"
        >
          Członkowie
        </Link>
        <Link
          className={activeLink === "music" ? "active" : ""}
          onClick={() => setActiveLink("music")}
          to="/music"
        >
          Muzyka
        </Link>
        <Link
          className={activeLink === "films" ? "active" : ""}
          onClick={() => setActiveLink("films")}
          to="/films"
        >
          Filmy
        </Link>
        <a
          className="youtube-button"
          href="https://www.youtube.com/@fabiantarasiuk5906"
        >
          <button className="primary-button">YouTube</button>
        </a>
        {auth().staff &&
          <a
            rel="noreferrer"
            target="_blank"
            className="youtube-button"
            href={`${BASE_BACKEND_URL}admin/`}
          >
            <button className="primary-button">Admin</button>

          </a>}
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <Link onClick={() => setActiveLink(item.url)} to={item.url}>
                <ListItem key={item.text} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <a href="https://www.youtube.com/@fabiantarasiuk5906">
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <YouTubeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Youtube" />
                </ListItemButton>
              </ListItem>
            </a>
            {auth().staff &&
              <a
                rel="noreferrer"
                target="_blank"
                href={`${BASE_BACKEND_URL}admin/`}
              >
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <Admin />
                    </ListItemIcon>
                    <ListItemText primary="Admin" />
                  </ListItemButton>
                </ListItem>
              </a>}
          </List>
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
