import "./App.css";
import RoutesComponent from "./routes";
import { AuthProvider } from "react-auth-kit";
import refreshApi from "./RefreshToken";

function App() {
  return (
    <AuthProvider
    authType={"cookie"}
    authName={"_auth"}
    refresh={refreshApi}
  >
    <RoutesComponent/>
  </AuthProvider>
  )
}

export default App;
