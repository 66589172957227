import { React, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { BsFillPlayBtnFill } from "react-icons/bs";
import BackgroundImage from "../assets/background/music-background.png";
import BackgroundImage2 from "../assets/background/music-background2.png";
import BackgroundImage3 from "../assets/background/music-background3.png";
import { Oval } from "react-loader-spinner";
import { BASE_API_URL } from "../components/consts/api"
import { useAuthHeader } from "react-auth-kit";

const MusicList = () => {
  const auth = useAuthHeader();

  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [musicList, setmusicList] = useState([]);
  useEffect(() => {
    const getSongs = async () => {
      setLoading(true);
      await fetch(`${BASE_API_URL}songs/`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Authorization": auth()
        },
      })
        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then(function (data) {
          if (Array.isArray(data)) {
            setmusicList(data);
          } else {
            setLoading(false);
            setErrorMsg(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorMsg("Błąd serwera 👨‍💻");
        });
    };
    getSongs();
  }, []);
  return (
    <div className="music-list-wrapper">
      <div className="music-list-top">
        <div className="music-list-background-container">
          <img src={BackgroundImage} alt="" />
        </div>
        <div className="music-list-background-container2">
          <img src={BackgroundImage3} alt="" />
        </div>
        <p className="primary-subheading">Piosneki grupy jak 5 zł</p>
        <p className="primary-heading">d-_-b</p>
      </div>
      <div className="music-list-bottom">
        <p className="error">{errorMsg}</p>
        {loading ? (
          <div className="loading-container">
            <Oval
              height={60}
              width={60}
              color="#fe9e0d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#fe9e0d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            {!musicList && <div className="music-list-background-container-middle">
              <img src={BackgroundImage2} alt="" />
            </div>}
            {musicList.map((song) => (
              <div className="music-list-box" key={song.url}>
                <div className="music-list-player">
                  <ReactPlayer
                    url={song.url}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div>
                <div className="music-list-description">
                  <div className="music-title">
                    <h2>{song.title}</h2>
                    <p className="primary-subheading">{song.all_authors}</p>
                  </div>
                  <a href={song.url}>
                    <button className="watch-video-button">
                      Posłuchaj na YouTube <BsFillPlayBtnFill />
                    </button>
                  </a>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default MusicList;
