import BannerBackground from "../assets/background/home-banner-background.png";
import BannerBackground2 from "../assets/background/people-list-background.png";
import BannerBackground3 from "../assets/background/youtube-section-background.png";
import { React, useState, useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import PersonModal from "./PersonModal";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Oval } from "react-loader-spinner";
import { BASE_API_URL } from "../components/consts/api"

const PeopleList = () => {
  const auth = useAuthHeader();
  const [chosenPerson, setChosenPerson] = useState();
  const [openModal, setOpenModal] = useState(false);
  openModal ? disableBodyScroll(document) : enableBodyScroll(document);

  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [peopleList, setpeopleList] = useState([]);
  useEffect(() => {
    const getPeople = async () => {
      setLoading(true);
      await fetch(`${BASE_API_URL}people/`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Authorization": auth(),
        },
      })
        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then(function (data) {
          if (Array.isArray(data)) {
            setpeopleList(data);
          } else {
            setLoading(false);
            setErrorMsg(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorMsg("Błąd serwera 👨‍💻");
        });
    };
    getPeople();
  }, []);

  return (
    <div className="people-list-wrapper">
      <div className="people-list-top">
        <div className="people-list-background-image-container">
          <img src={BannerBackground2} alt="" />
        </div>
        <div className="peolpe-list-background-container2">
          <img src={BannerBackground} alt="" />
        </div>
        <p className="primary-subheading">Członkowie grupy jak 5 zł</p>
        <p className="primary-heading">Lista oficjalnych członków</p>
      </div>
      <div className="people-list-bottom">
        <p className="error">{errorMsg}</p>
        {loading ? (
          <div className="loading-container">
            <Oval
              height={60}
              width={60}
              color="#fe9e0d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#fe9e0d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <>
            {peopleList.map((person) => (
              <div className="people-list-info" id={person.id} key={person.id}>
                <div className="info-boxes-img-container">
                  <img src={person.image} alt={person.first_name} />
                </div>
                <h2>
                  {person.first_name} {person.last_name}
                </h2>
                <p className="primary-subheading">{person.short_description}</p>
                <button
                  className="read-more-button"
                  onClick={() => {
                    setChosenPerson(person);
                    setOpenModal(true);
                  }}
                >
                  więcej
                </button>
              </div>
            ))}
            {!peopleList &&
              <div className="people-list-background-image-container-middle">
                <img src={BannerBackground3} alt="" />
              </div>}
          </>
        )}
      </div>
      {openModal && (
        <PersonModal
          closeModal={setOpenModal}
          person={chosenPerson}
          setChosenPerson={setChosenPerson}
          peopleList={peopleList}
        />
      )}
    </div>
  );
};

export default PeopleList;
