import { React, useState, useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import { Oval } from "react-loader-spinner";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { HashLink } from "react-router-hash-link";
import { BASE_API_URL } from "../components/consts/api"

const PeopleSnippet = () => {
  const auth = useAuthHeader();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [peopleList, setpeopleList] = useState([]);
  useEffect(() => {
    const getPeople = async () => {
      setLoading(true);
      await fetch(`${BASE_API_URL}people/`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Authorization": auth()
        },
      })
        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then(function (data) {
          if (Array.isArray(data)) {
            setpeopleList(data);
          } else {
            setLoading(false);
            setErrorMsg(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorMsg("Błąd serwera 👨‍💻");
        });
    };
    getPeople();
  }, []);

  return (
    <div className="people-list-wrapper">
      <div className="people-list-top">
        <p className="primary-subheading">Członkowie</p>
        <h1 className="primary-heading">Skład grupy</h1>
        <p className="primary-text">Bez nich "Grupa jak 5zł" by nie istniała</p>
        <p className="error">{errorMsg}</p>
      </div>
      {loading ? (
        <div className="loading-container">
          <Oval
            height={60}
            width={60}
            color="#fe9e0d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#fe9e0d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
        >
          {peopleList.map((person) => (
            <div
              key={person.short_description}
              className="people-slider-section-bottom"
            >
              <img src={person.image} alt={person.short_description} />
              <h2>
                {person.first_name} {person.last_name}
              </h2>
              <p>{person.short_description}</p>
              <HashLink to={"people#" + person.id}>
                <button className="read-more-button">Lista członków</button>
              </HashLink>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default PeopleSnippet;
