import { React, useState, useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import Image from "../assets/aboutPage/gallery/grupa52.png";
import { Link } from "react-router-dom";
import BackgroundImage from "../assets/background/about-section-background.png";
import { BASE_API_URL } from "../components/consts/api"

const AboutMainSection = () => {
  const auth = useAuthHeader();
  const [peopleList, setpeopleList] = useState();
  useEffect(() => {
    const getPeople = async () => {
      await fetch(`${BASE_API_URL}people/`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Authorization": auth(),
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (Array.isArray(data)) {
            setpeopleList(data.length);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
          setpeopleList(14);
        });
    };
    getPeople();
  }, []);
  return (
    <div className="about-main-section-wrapper">
      <div className="about-main-background">
        <img src={BackgroundImage} alt="" />
      </div>
      <div className="about-main-section">
        <div className="about-main-section-description">
          <h1 className="primary-heading">O grupie</h1>
          <p className="primary-text">
            Jesteśmy najlepszą grupą na świecie, na której czele stoi potężny
            wódz Jakub Tarasiewicz. Jego nadprzyrodzona umiejętność pozwalająca
            mu przybierać formę goryla pozwala zachować porządek wśród ekipy.
            Aktualnie{" "}
            <Link className="inside-text-link" to="/people/">
              oficjalna lista członków
            </Link>{" "}
            liczy {peopleList} osób. W tym momencie jesteśmy w trakcie rekrutowania nowych
            członków - Kamila Łachudry, Grzegorza Europejskiego i Amadeusza
            Młota.
          </p>
        </div>
        <div className="about-main-section-image">
          <img src={Image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AboutMainSection;
