import React from "react";
import { Link } from "react-router-dom";
import BannerBackground from "../assets/background/home-banner-background.png";
import LogoImage from "../assets/5-zl-image.png";
import { FiArrowRight } from "react-icons/fi";

const HomeBanner = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            Grupa jak 5 zł - najlepsza grupa na świecie
          </h1>
          <p className="primary-text">
            Je*ać każdy zakaz, welcome to Jackass!
          </p>
          <Link to="/about">
            <button className="secondary-button">
              Dowiedz się więcej <FiArrowRight />
            </button>
          </Link>
        </div>
        <div className="home-image-container">
          <img src={LogoImage} alt="Grupa jak 5 zł" />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
