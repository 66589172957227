import { React, useEffect, useState } from "react";
import { FaAngleRight, FaAngleLeft, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { refreshPage } from "./utils";

function PersonModal({ closeModal, person, setChosenPerson, peopleList }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1000px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 1000px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const prevPerson = () => {
    console.log(peopleList.indexOf(person))
    peopleList.indexOf(person) === 0
      ? setChosenPerson(peopleList[peopleList.length-1])
      : setChosenPerson(peopleList[peopleList.indexOf(person)-1]);
  };

  const nextPerson = () => {
    peopleList.indexOf(person) === peopleList.length - 1
      ? setChosenPerson(peopleList[0])
      : setChosenPerson(peopleList[peopleList.indexOf(person)+1]);
  };

  return (
    <div className="modal">
      <div className="modal-background">
        <div className="person-modal">
          {matches && (
            <div className="person-modal-content">
              <div className="person-modal-image-section">
                <img src={person.image} alt="" />
              </div>
              <div className="person-modal-description">
                <h1 className="primary-heading">
                  {person.first_name} {person.last_name}
                </h1>
                <h1 className="primary-subheading">
                  {person.short_description}
                </h1>
                <p
                  className="primary-text"
                  dangerouslySetInnerHTML={{ __html: person.long_description }}
                ></p>
              </div>
              {person.first_name === "Dawid" && (
                <Link className="game-button" onClick={refreshPage} to="/game/">
                  <button className="secondary-button">Ignatki wzywają</button>
                </Link>
              )}
            </div>
          )}
          {!matches && (
            <div className="person-modal-content">
              <div className="person-modal-image-section">
                <img src={person.image} alt="" />
                <div className="person-modal-name-section">
                  <h1 className="primary-heading">
                    {person.first_name} {person.last_name}
                  </h1>
                  <h1 className="primary-subheading" lang="pl">
                    {person.short_description}
                  </h1>
                </div>
              </div>
              <div className="person-modal-description">
                <p
                  className="primary-text"
                  dangerouslySetInnerHTML={{ __html: person.long_description }}
                ></p>
              </div>
            </div>
          )}
          <button onClick={() => closeModal(false)}>
            <FaTimes />
          </button>
        </div>
        <FaAngleLeft
          onClick={prevPerson}
          className="person-modal-prev-button"
        />
        <FaAngleRight
          onClick={nextPerson}
          className="person-modal-next-button"
        />
      </div>
    </div>
  );
}

export default PersonModal;
