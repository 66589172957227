import { React, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { BsFillPlayBtnFill } from "react-icons/bs";
import BannerBackground from "../assets/background/films-background.png";
import BannerBackground2 from "../assets/background/films-background2.png";
import BannerBackground3 from "../assets/background/films-background3.png";
import { Oval } from "react-loader-spinner";
import { BASE_API_URL } from "../components/consts/api"
import { useAuthHeader } from "react-auth-kit";

const FilmList = () => {
  const auth = useAuthHeader();

  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [filmsList, setfilmsList] = useState([]);
  useEffect(() => {
    const getVideos = async () => {
      setLoading(true);
      await fetch(`${BASE_API_URL}videos/`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Authorization": auth(),
        },
      })
        .then(function (response) {
          setLoading(false);
          return response.json();
        })
        .then(function (data) {
          if (Array.isArray(data)) {
            setfilmsList(data);
          } else {
            setLoading(false);
            setErrorMsg(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorMsg("Błąd serwera 👨‍💻");
        });
    };
    getVideos();
  }, []);
  return (
    <div className="film-list-wrapper">
      <div className="film-list-top">
        <div className="film-list-background-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="film-list-background-container2">
          <img src={BannerBackground2} alt="" />
        </div>
        <p className="primary-subheading">Filmy grupy jak 5 zł</p>
        <p className="primary-heading">Zapraszamy do seansu</p>
      </div>
      <p className="error">{errorMsg}</p>
      {loading ? (
          <div className="loading-container">
            <Oval
              height={60}
              width={60}
              color="#fe9e0d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#fe9e0d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
      <div className="film-list-bottom">
        {filmsList.map((film) => (
          <div
            class={filmsList.indexOf(film) % 2 === 0 ? "film-list-box" : "film-list-box-odd"}
            key={film.id}
          >
            <div className="film-list-player">
              <ReactPlayer
                url={film.url}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
            <div className="film-list-description">
              <h2>{film.title}</h2>
              <p className="primary-text">{film.description}</p>
              <a href={film.url}>
                <button className="watch-video-button">
                  Zobacz film na YouTube <BsFillPlayBtnFill />
                </button>
              </a>
            </div>
          </div>
        ))}
        {!filmsList &&
        <div className="film-background-image-container-middle">
          <img src={BannerBackground3} alt="" />
        </div>}
      </div>
        )}
    </div>
  );
};

export default FilmList;
