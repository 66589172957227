import React from "react";
import HomeBanner from "../components/HomeBanner";
import PeopleSnippet from "../components/PeopleSnippet";
import YoutubeSection from "../components/YoutubeSection";

const Home = () => {
    return (
        <>
            <HomeBanner />
            <YoutubeSection />
            <PeopleSnippet />
        </>
    );
  };
  
  export default Home;
