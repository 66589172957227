import { React, useState } from "react";
import { FaAngleRight, FaAngleLeft, FaTimes } from "react-icons/fa";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const Gallery = ({ images }) => {
  const [imageNumber, setImageNumber] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (id) => {
    setImageNumber(id);
    setOpenModal(true);
  };

  const prevImage = () => {
    imageNumber === 0
      ? setImageNumber(images.length - 1)
      : setImageNumber(imageNumber - 1);
  };

  const nextImage = () => {
    imageNumber === images.length - 1
      ? setImageNumber(0)
      : setImageNumber(imageNumber + 1);
  };

  openModal ? disableBodyScroll(document) : enableBodyScroll(document);

  return (
    <>
      {openModal && (
        <div className="gallery-slider-wrapper">
          <div className="gallery-fullscreen-single">
            <FaTimes
              onClick={() => setOpenModal(false)}
              className="gallery-close-button"
            />
            <FaAngleLeft onClick={prevImage} className="gallery-prev-button" />
            <FaAngleRight onClick={nextImage} className="gallery-next-button" />
            <img src={images[imageNumber].image} alt="" />
          </div>
        </div>
      )}

      <div className="gallery-wrapper">
        <div className="gallery-images">
          {images &&
            images.map((image) => {
              return (
                <div
                  className="gallery-single"
                  key={image.id}
                  onClick={() => handleOpenModal(images.indexOf(image))}
                >
                  <img src={image.image} alt="" />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Gallery;
