import React from "react";
import Peugeot from "../assets/game/peugeot.png";
import GroundImage from "../assets/game/ground.png";
import useScript from "../hooks/useScript";
import { BrowserView, MobileView } from "react-device-detect";
import { Link } from "react-router-dom";
import { refreshPage } from "../components/utils";
import { Oval } from 'react-loader-spinner';

const Game = () => {
  useScript("/game/world.js");
  return (
    <>
          <BrowserView>
            <div className="game-loading-page">
              <Oval
                height={200}
                width={200}
                color="#fe9e0d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#fe9e0d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
            <Link className="go-back-button" onClick={refreshPage} to="/">
              <button className="secondary-button">Wróć na stronę</button>
            </Link>
            <div className="world" id="world" data-world>
              <div className="score" data-score>
                0
              </div>
              <div className="start-screen" data-start-screen>
                Najciśnij dowolny przycisk aby zagrać
              </div>
              <div className="hide" data-boss-fight>
                Night Racing Ignatki zwyciężyły
              </div>
              <img src={GroundImage} alt="" className="ground" data-ground />
              <img src={GroundImage} alt="" className="ground" data-ground />
              <img src={Peugeot} alt="" className="character" data-character />
            </div>
          </BrowserView>
          <MobileView>
            <div className="mobile-screen">
              <h1 className="mobile-message">To gra komputerowa, nie telefonowa</h1>
              <Link onClick={refreshPage} to="/">
                <button className="secondary-button">Wróć na stronę</button>
              </Link>
            </div>
          </MobileView>
        </>
  );
};

export default Game;
