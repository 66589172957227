import { React, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import LogoImage from "../assets/logo-white.png";
import BackgroundImage from "../assets/loginPage/background.png";
import { Oval } from "react-loader-spinner";
import { BASE_API_URL } from "../components/consts/api"

const Login = () => {
  const signIn = useSignIn();
  const navigate = useNavigate();

  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMsg(null);
    fetch(`${BASE_API_URL}token/`, {
      method: "POST",
      body: JSON.stringify({
        username: login,
        password: password,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then(function (response) {
        setLoading(false);
        return response.json();
      })
      .then(function (data) {
        if (data.access) {
          if (
            signIn({
              token: data.access,
              refreshToken: data.refresh,
              expiresIn: 300,
              refreshTokenExpireIn: 1440,
              tokenType: "Bearer",
              authState: { staff: data.is_staff },
            })
          ) {
            navigate("/");
          }
        } else {
          setLoading(false);
          setErrorMsg(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setErrorMsg("Błąd serwera 👨‍💻");
      });
  };

  return (
    <div className="login">
      <div className="background">
        <img src={BackgroundImage} alt="" />
      </div>
      <form className="login-form">
        <div className="title-section">
          <span className="background-blob"></span>
          <h2 className="primary-heading">Grupa jak 5 zł</h2>
          <div className="image-container">
            <img src={LogoImage} alt="" />
          </div>
        </div>
        <p className="error">{errorMsg}</p>
        <div className="input-container">
          <input
            type="text"
            name="login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
          />
          <span></span>
          <label>Login</label>
        </div>
        <div className="input-container">
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span></span>
          <label>Hasło</label>
        </div>
        <div className="button-container">
          {loading ? (
            <div className="loading-container">
              <Oval
                height={60}
                width={60}
                color="#fe9e0d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#fe9e0d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <input
              className="secondary-button"
              type="submit"
              value={"Zaloguj się"}
              onClick={handleSubmit}
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;
